import React, {useEffect, useState, useMemo, Suspense} from "react"
import { StaticQuery, graphql } from "gatsby"
import gui from "./synth_view.js";
// console.log(gui)
export default function PianoRoll({links}){
  useEffect((e)=> {
    // var guiz = gui.init();
    if(typeof window !== "undefined"){
      gui(links)
    }
  },[])
  return <div id="pianoroll"></div>
}
